import React from "react";
import { Route, Routes, BrowserRouter, Link } from "react-router-dom";
import RFQ from "./components/RFQ";
import Authenticator from "./components/Authenticator";
import TransactionDetails from "./components/TransactionDetails";
import TransactionSearch from "./components/TransactionSearch";
import LatestOrders from "./components/LatestOrders";
import Latest from "./components/Latest";
import Benchmarker from "./components/Benchmarker";
import BenchmarkerAuthenticator from "./components/BenchmarkerAuthenticator";
import { IconButton, MoonIcon, SunIcon, ThemeProvider, useUserTheme } from "@0xsquid/ui";

declare global {
  interface Window {
    ethereum?: any;
  }
}

const App: React.FC = () => {
  const { theme, themeType, isDarkMode, setThemeManually } = useUserTheme();

  return (
    <BrowserRouter>
      <ThemeProvider
        theme={{
          ...theme,
          color: {
            ...theme.color,
            // @ts-expect-error - add additional color
            "royal-800": isDarkMode ? "#514365" : "#F1EDF7",
          },
        }}
        themeType={themeType}
        className="!tw-h-auto !tw-w-full tw-bg-grey-900 tw-text-grey-100"
      >
        <menu className="tw-absolute tw-top-squid-m tw-right-squid-m tw-z-[1000] tw-flex tw-items-center tw-gap-squid-xs">
          <Link
            to="/latest"
          >
            /latest
          </Link>

          <IconButton
            icon={
              isDarkMode ? (
                <MoonIcon size="25" className="tw-text-highlight-700" />
              ) : (
                <SunIcon size="25" className="tw-text-royal-500" />
              )
            }
            onClick={() => setThemeManually(isDarkMode ? "light" : ("dark" as any))}
          ></IconButton>
        </menu>

        <main className="tw-w-full tw-h-full tw-z-0 tw-min-h-screen tw-grid">
          <Routes>
            <Route path="/" element={<TransactionSearch />} />
            <Route path="/tx" element={<TransactionSearch />} />
            <Route path="/tx/:transactionHash" element={<TransactionDetails />} />
            <Route path="/latest" element={<Latest />} />
            <Route path="/latest/:address" element={<LatestOrders />} />
            <Route
              path="/dashboard"
              element={
                <Authenticator>
                  <RFQ />
                </Authenticator>
              }
            />
            <Route
              path="/benchmarker"
              element={
                <BenchmarkerAuthenticator>
                  <Benchmarker />
                </BenchmarkerAuthenticator>
              }
            />
          </Routes>
        </main>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
