import { BodyText, Checkmark1Icon, ClockOutlineIcon, cn, ReorderIcon } from "@0xsquid/ui";
import React from "react";
import { useMemo } from "react";
import { SquidRFQOrder } from "src/types";
import { currentTime } from "src/utils/constants";
import { formatDateTime, formatElapsedTime, formatTxHash } from "src/utils/formatting";
import { OrderRowCell } from "./OrderRowCell";
import { Link } from "react-router-dom";
import { TransferDetails } from "../TransferDetails";

enum OrderStatus {
  FILLED = "Filled",
  REFUNDED = "Refunded",
  FILLING = "Filling",
}

const orderStatusToIconMap: Record<OrderStatus, React.ReactNode> = {
  [ OrderStatus.FILLED ]: <Checkmark1Icon size="16px" />,
  [ OrderStatus.REFUNDED ]: <ReorderIcon size="16px" />,
  [ OrderStatus.FILLING ]: <ClockOutlineIcon size="16px" />,
};

const orderStatusToClassMap: Record<OrderStatus, string> = {
  [ OrderStatus.FILLED ]: "tw-text-status-positive",
  [ OrderStatus.REFUNDED ]: "tw-text-status-partial",
  [ OrderStatus.FILLING ]: "tw-text-grey-300",
};

interface OrderRowProps {
  order: SquidRFQOrder;
}

export function OrderRow({ order }: OrderRowProps) {
  const { elapsedTime, status } = useMemo<{
    status: OrderStatus;
    elapsedTime: string | null;
  }>(() => {
    let elapsedTime = "success";

    if (order.orderFilledTxHash) {
      elapsedTime = formatElapsedTime(
        order.orderFilledBlockTimestamp - order.orderCreatedBlockTimestamp,
      );

      return {
        elapsedTime,
        status: OrderStatus.FILLED,
      };
    } else if (order.orderRefundedTxHash) {
      elapsedTime = formatElapsedTime(order.orderRefundedBlockTimestamp - order.orderCreatedBlockTimestamp);

      return {
        elapsedTime,
        status: OrderStatus.REFUNDED,
      };
    } else {
      elapsedTime = formatElapsedTime(currentTime - order.orderCreatedBlockTimestamp);
      return {
        elapsedTime,
        status: OrderStatus.FILLING,
      };
    }
  }, [ order ]);

  return (
    <tr className="table-padding-x tw-h-squid-xxl tw-p-squid-m order-row">
      <OrderRowCell>
        <BodyText size="small" className="tw-text-royal-500 tw-font-mono">
          <Link to={`/tx/${order.id}`}>{formatTxHash(order.id)}</Link>
        </BodyText>
      </OrderRowCell>

      <OrderRowCell>
        <TransferDetails
          amount={order.fromAmount}
          chain={order.fromChain}
          tokenSymbol={order.fromTokenSymbol}
          tokenDecimals={order.fromTokenDecimals}
        />
      </OrderRowCell>

      <OrderRowCell>
        <TransferDetails
          amount={order.fillAmount}
          chain={order.toChain}
          tokenSymbol={order.toTokenSymbol}
          tokenDecimals={order.toTokenDecimals}
        />
      </OrderRowCell>

      <OrderRowCell>
        <div className="tw-flex tw-items-center tw-gap-squid-xs tw-flex-1 tw-self-stretch">
          <div
            className={cn(
              "tw-flex tw-items-center tw-justify-center tw-gap-squid-xxs tw-rounded-squid-m tw-h-squid-m",
              orderStatusToClassMap[ status ],
            )}
          >
            {orderStatusToIconMap[ status ]}

            <BodyText size="small">{status}</BodyText>
          </div>

          {elapsedTime != null && (
            <div className="tw-flex tw-h-squid-m tw-justify-center tw-items-center tw-gap-squid-xxs tw-text-grey-500">
              {elapsedTime === "1s" ? <FireIcon /> : <ClockOutlineIcon size="16px" />}

              <BodyText className="tw-whitespace-nowrap" size="small">
                {elapsedTime}
              </BodyText>

            </div>
          )}
        </div>
      </OrderRowCell>

      <OrderRowCell>{formatDateTime(order.orderCreatedBlockTimestamp)}</OrderRowCell>
    </tr>
  );
}

function FireIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className="fire-icon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29792 2.39901C9.78493 1.70328 10.7622 1.45179 11.5255 1.97659C12.3185 2.52188 13.69 3.57579 14.8703 5.05529C16.0501 6.53405 17.0833 8.49518 17.0833 10.8333C17.0833 14.9978 13.9564 18.3333 9.99996 18.3333C6.04348 18.3333 2.91663 14.9978 2.91663 10.8333C2.91663 9.13719 3.64503 6.97003 5.06457 5.23983C5.68651 4.48178 6.75968 4.527 7.38668 5.12936L9.29792 2.39901ZM9.99996 16.6666C11.2081 16.6666 12.1875 15.5487 12.1875 14.1697C12.1875 12.6899 11.0365 11.6014 10.4043 11.1152C10.1629 10.9295 9.83699 10.9295 9.59563 11.1152C8.96345 11.6014 7.81246 12.6899 7.81246 14.1697C7.81246 15.5487 8.79184 16.6666 9.99996 16.6666Z"
        fill="currentColor"
      />
    </svg>
  );
}
